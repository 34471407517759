
import { defineComponent, ref, watch, computed } from "vue";
import { useStore } from "@/store/index";
import { createIteration } from "@/api/request-modules/iteration";
import useMessageTip from "@/composables/useMessageTip";
import { ResponseParams } from "@/types/response";
import useAddOrEditDIalogForm from "./composables/useAddOrEditDIalogForm";
import useMixin, { productId } from "../../useMixin";
import useGetDemandList from "../../useGetDemandList";
import router from "@/router";
import { getStaffList } from "@/api/request-modules/common";
import { getSession, setSession } from "@/utils";
import { MutationType } from "@/store/mutation-types";
export default defineComponent({
  emits: ["refresh"],
  setup() {
    const store = useStore();
    const isDisable = ref(false);
    const isSuccessAfter = ref(false);
    const employeeLists = ref();
    const { rules, handleChangeDialogStatus, isShowIteration, title, ruleFormRef, formData, hasTime, checkBoxStatus } = useAddOrEditDIalogForm();
    const { tipMessage } = useMessageTip();
    const currentIter = computed(() => store.getters.currentIter);

    const setFiled = (staff_no: string | number, filed: string) => {
      const staff = employeeLists.value[1]?.options.find((list: Record<string, any>) => list.staff_no === staff_no);
      (formData as any)[filed].staff_no = staff.staff_no;
      (formData as any)[filed].staff_name = staff.staff_name;
    };

    watch(
      () => isShowIteration.value,
      (newValue) => {
        if (newValue) {
          // 获取产品负责人员工列表
          getStaffList<ResponseParams.ResponseDataSuccess>({
            product_id: productId.value as number,
            iteration_id: formData.id ? currentIter.value?.id : undefined,
            is_create: true
          }).then((res) => {
            employeeLists.value = res.data;
          });
        } else {
          checkBoxStatus.value = false;
        }
      }
    );
    // val跳转路由名字  num代表跳转后是否进行其他时间
    const handleConfirmGo = (val?: string) => {
      if (!val) return (isSuccessAfter.value = false);
      if (val && val !== "homepage" && val !== "addmember") {
        router.push({ name: val });
      } else if (val === "homepage") {
        // 需要先跳转到迭代主页
        if (router.currentRoute.value.path !== "/project/iteration/homepage") {
          router.push({
            name: "homepage"
          });
          isSuccessAfter.value = false;
          setTimeout(() => {
            store.commit(MutationType.iterateContentVisible, true);
          }, 1000);
          return;
        }
        store.commit(MutationType.iterateContentVisible, true);
      } else if (val === "addmember") {
        // 需要先跳转到迭代主页
        if (router.currentRoute.value.path !== "/project/iteration/homepage") {
          router.push({
            name: "homepage"
          });
          isSuccessAfter.value = false;
          setTimeout(() => {
            store.commit(MutationType.addMemberVisible, true);
          }, 2000);
          return;
        }
        store.commit(MutationType.addMemberVisible, true);
      }
      isSuccessAfter.value = false;
    };

    const iterationName = ref("");
    const { searchParams, setIterationDetail, demandList } = useMixin(true);
    const handleCreateIteration = () => {
      ruleFormRef.value!.validate((valid: boolean) => {
        if (valid) {
          if (Reflect.toString.call(formData.plan_id) == "[object Array]") {
            formData.plan_id = (formData.plan_id as any)[1];
          }
          formData.product_id = productId.value as number;
          const newFormData: any = JSON.parse(JSON.stringify(formData));
          if (checkBoxStatus.value) {
            delete newFormData.dev_time;
            delete newFormData.release_time;
            delete newFormData.test_time;
            delete newFormData.union_time;
            newFormData.is_schedule = 0;
          } else {
            newFormData.is_schedule = 1;
          }
          // 创建迭代/更新迭代
          isDisable.value = true;
          createIteration<ResponseParams.ResponseDataSuccess>(newFormData).then(async (res) => {
            if (res.code !== 200) return;
            tipMessage(res.code);
            handleChangeDialogStatus(true);
            // 获取最新迭代列表
            await store.dispatch("GETITERATELIST", formData.product_id);
            // 新增的时候，需要切换到新的迭代
            const iterationId = (res.data as Record<string, any>).id;
            getDemandListFn(productId.value!, iterationId);
            setIterationDetail(res.data);
            isDisable.value = false;
            if (!newFormData.id) {
              isSuccessAfter.value = true;
              iterationName.value = newFormData.name;
            }
            const { query } = router.currentRoute.value;
            if (router.currentRoute.value.name !== "homepage") {
              router.push({
                // @ts-ignore
                name: "homepage",
                query: {
                  ...query,
                  productId: productId.value,
                  iterateId: iterationId
                }
              });
            }
          });
        } else {
          return false;
        }
      });
    };
    // 重新获取需求列表
    const getDemandList = useGetDemandList();
    const getDemandListFn = (projectId: number, iterationId: number) => {
      getDemandList(projectId).then((res) => {
        // 需要先更新id在更改列表
        searchParams.demand = iterationId;
        demandList.value = res;
        const curIter = res.find((i: Record<string, any>) => i.id === iterationId);
        store.commit(MutationType.updateCurrentIter, curIter);
        store.commit(MutationType.updateIterateId, iterationId);
        const _cache = getSession("cacheProject", true) as Array<any>;
        _cache.forEach((v, index) => {
          if (v.projectId === projectId) {
            _cache[index].iterationId = iterationId;
          }
        });
        setSession("cacheProject", JSON.stringify(_cache));
      });
    };
    const handleChangeDevTime = () => {
      formData.union_time = null;
      formData.test_time = null;
      formData.release_time = null;
    };
    const handleChangeUnionTime = () => {
      if (!formData.dev_time) {
        formData.union_time = null;
        return tipMessage(500, "请按顺序选择时间(开发-联调-提测-发布)");
      }
      formData.test_time = null;
      formData.release_time = null;
    };
    const handleChangeTestTime = () => {
      if (!formData.dev_time || !formData.union_time) {
        formData.test_time = null;
        return tipMessage(500, "请按顺序选择时间(开发-联调-提测-发布)");
      }
      formData.release_time = null;
    };
    const handleChangeReleaseTime = () => {
      if (!formData.dev_time || !formData.union_time || !formData.test_time) {
        formData.release_time = null;
        return tipMessage(500, "请按顺序选择时间(开发-联调-提测-发布)");
      }
    };
    const disabledUnionDateTime = (pickerTime: Date) => {
      if (formData.dev_time) {
        return pickerTime.getTime() <= new Date(formData.dev_time).getTime() - 86400000;
      }
    };
    const disabledTestDateTime = (pickerTime: Date) => {
      if (formData.union_time) {
        return pickerTime.getTime() <= new Date(formData.union_time).getTime() - 86400000;
      }
    };
    const disabledReleaseDateTime = (pickerTime: Date) => {
      if (formData.test_time) {
        return pickerTime.getTime() <= new Date(formData.test_time).getTime() - 86400000;
      }
    };
    //#endregion
    return {
      isDisable,
      handleChangeDevTime,
      handleChangeUnionTime,
      handleChangeTestTime,
      handleChangeReleaseTime,
      disabledReleaseDateTime,
      disabledTestDateTime,
      disabledUnionDateTime,
      ruleFormRef,
      rules,
      title,
      formData,
      isShowIteration,
      handleCreateIteration,
      handleChangeDialogStatus,
      employeeLists,
      setFiled,
      hasTime,
      checkBoxStatus,
      isSuccessAfter,
      handleConfirmGo,
      iterationName
    };
  }
});
