
import ProjectNav from "./components/projectNav.vue";
import RouterNav from "./components/routerNav.vue";
import ToolNav from "./components/toolNav.vue";
export default {
  name: "header",
  components: {
    ProjectNav,
    RouterNav,
    ToolNav
  }
};
