import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6370ea24"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProjectNav = _resolveComponent("ProjectNav")!
  const _component_RouterNav = _resolveComponent("RouterNav")!
  const _component_ToolNav = _resolveComponent("ToolNav")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createVNode(_component_ProjectNav),
    _createVNode(_component_RouterNav),
    _createVNode(_component_ToolNav)
  ]))
}