
import { defineComponent, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "@/store/index";
import { clearSession, getSession } from "@/utils";
import { MutationType } from "@/store/mutation-types";
import useCommon from "@/composables/useCommon";
import { USER } from "@/store/state";
import { SourcePermiss } from "@/utils/enum";
import { systemBehaviorStore, userBehaviorStore } from "@/utils/contantOptions";
// 除了信息技术部外的配置路由权限
const externalName = "demand";
export const externalRouterPermiss = ["workbench", "project", "profileOverview"];

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    const { useGetSystemUpdateInfo } = useCommon();
    let timer: ReturnType<typeof setTimeout>;
    const receiveData = computed(() => store.state.businessReceiveData);

    const user = getSession(systemBehaviorStore.systemUserinfo, true) as USER;
    const productId = computed(() => store.getters.productId);
    const path = computed(() => {
      clearSession("jumpUrl");
      return `/${router.currentRoute.value.path.split("/")[1]}`;
    });

    const menu = computed(() => {
      const menuArr = router
        .getRoutes()
        .find((router) => router.name === "layout")!
        .children.filter((v) => {
          let childrenList = v.children ? v.children : [];
          v.children = childrenList.filter((item) => item.meta?.hidden !== false);
          return v.meta?.hidden !== false;
        });
      // 判断有无权限访问配置模块，过滤掉路由configure
      if (store.state.permission) {
        if (!store.state.permission.config_operation) {
          menuArr.forEach((item, index) => {
            if (item.name === "configure") menuArr.splice(index, 1);
          });
        }
      }

      // 集团外部人员可查看个人工作台、项目、我的模块；集团内非番茄配置部门/人员可查看个人工作台、项目、我的模块、需求提报，无法查看文档、看板、配置等模块；
      if (user && user?.source > SourcePermiss.DEPARTMENTSTAFF) {
        // 如果是集团内非番茄配置人员，需要价格一个需求提报
        if (user?.source === SourcePermiss.GROUPSTAFF) {
          externalRouterPermiss.push("business");
        } else {
          // 外部用户需要把提报需求给去掉
          const idx = menuArr.findIndex((i) => i.name === "business");
          if (idx > -1) {
            menuArr.splice(idx, 1);
          }
        }

        return menuArr.filter((item) => externalRouterPermiss.includes(item.name as string));
      }

      return menuArr;
    });
    const linkToPage = (item: any) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        // 路由发生错误时，不要请求更新提醒
        if (!store.state.hasRouteError) {
          useGetSystemUpdateInfo();
        }
      }, 1000);
      const { query, meta } = router.currentRoute.value;
      const _query = meta.hideQuery ? {} : query;

      // 如果是SourcePermiss.DEPARTMENTSTAFF 集团内部非番茄配置人员，左侧栏需要显示需求提报
      if (item.name === externalName) {
        const a = router.resolve({
          name: "businessReport"
        });
        window.open(a.href, "_blank");
        return;
      } else if (item.name === "project" && !getSession(userBehaviorStore.isRecentProject)) {
        // 如果点击侧边栏项目时，查看是否有选中记录
        router.push({
          name: "projectList"
        });
        return;
      }

      // 如果id不存在
      if (!productId.value) {
        const id = store.getters.productList[0]?.id;
        if (id) {
          store.commit(MutationType.updateProductId, id);
        }
      }
      router.push({
        path: item.path,
        query: {
          ..._query,
          productId: item.name === "project" ? productId.value : undefined
        }
      });
    };

    return {
      linkToPage,
      path,
      menu,
      receiveData
    };
  }
});
